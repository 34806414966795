// ** React Imports
import { Fragment } from 'react'

import { useSelector } from 'react-redux'

// ** Routes Imports
import AppRoutes from './Apps'
import FormRoutes from './Forms'
import PagesRoutes from './Pages'
import TablesRoutes from './Tables'
import ChartsRoutes from './Charts'
import DashboardRoutes from './Dashboards'
import UiElementRoutes from './UiElements'
import ExtensionsRoutes from './Extensions'
import PageLayoutsRoutes from './PageLayouts'
import AuthenticationRoutes from './Authentication'
import IMSRoutes from './IMS'

// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import { Navigate } from 'react-router-dom'
import { getUserData } from '../../utility/Utils'

const getLayout = {
	blank: <BlankLayout />,
	vertical: <VerticalLayout />,
	horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/dashboard/ecommerce'
const IMSDefaultRoute = '/dashboard'

// ** Merge Routes
const Routes = [...IMSRoutes, ...AuthenticationRoutes, ...DashboardRoutes, ...AppRoutes, ...PagesRoutes, ...UiElementRoutes, ...ExtensionsRoutes, ...PageLayoutsRoutes, ...FormRoutes, ...TablesRoutes, ...ChartsRoutes]

const getRouteMeta = (route) => {
	if (isObjEmpty(route.element.props)) {
		if (route.meta) {
			return { routeMeta: route.meta }
		} else {
			return {}
		}
	}
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
	const LayoutRoutes = []

	if (Routes) {
		Routes.filter((route) => {
			// const user = getUserData()
			const authenticationStore = useSelector((state) => state.auth)
			let user
			Object.keys(authenticationStore.userData).length ? user = authenticationStore.userData : user = getUserData()

			if (route.path === '/users/view') {
				const navigateUserID = user ? user.id : '0'
				route.element = <Navigate to={`/users/view/${navigateUserID}`} />
			}
			if (route.path === '/organizations/view') {
				const navigateOrgID = user ? user.org.id : '0'
				route.element = <Navigate to={`/organizations/view/${navigateOrgID}`} />
			}
			if (route.path === '/projects/view' && user && user.proj) {
				const navigateProjID = user ? user.proj.id : '0'
				route.element = <Navigate to={`/projects/view/${navigateProjID}`} />
			}
			let isBlank = false
			// ** Checks if Route layout or Default layout matches current layout
			if ((route.meta && route.meta.layout && route.meta.layout === layout) || ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)) {
				let RouteTag = PrivateRoute

				// ** Check for public or private route
				if (route.meta) {
					route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
					RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
				}
				if (route.element) {
					const Wrapper =
						// eslint-disable-next-line multiline-ternary
						isObjEmpty(route.element.props) && isBlank === false
							? // eslint-disable-next-line multiline-ternary
							LayoutWrapper
							: Fragment

					route.element = (
						<Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
							<RouteTag route={route}>{route.element}</RouteTag>
						</Wrapper>
					)
				}

				// Push route to LayoutRoutes
				LayoutRoutes.push(route)
			}
			return LayoutRoutes
		})
	}
	return LayoutRoutes
}

const getRoutes = (layout) => {
	const defaultLayout = layout || 'vertical'
	const layouts = ['vertical', 'horizontal', 'blank']

	const AllRoutes = []

	layouts.forEach((layoutItem) => {
		const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

		AllRoutes.push({
			path: '/',
			element: getLayout[layoutItem] || getLayout[defaultLayout],
			children: LayoutRoutes
		})
	})
	return AllRoutes
}

export { DefaultRoute, IMSDefaultRoute, TemplateTitle, Routes, getRoutes }
