// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import supabase from '../../../../utility/Supabase'

import { getUserData, handleError, handleSuccess } from '../../../../utility/Utils'

// const currentUser = getUserData()

function checkIfProjectExists(projects, projId) {
	return projects.some((project) => Object.keys(project).indexOf(projId) > -1)
}

export const getAllData = createAsyncThunk('IMSProjects/getAllData', async () => {
	const response = await axios.get('/api/users/list/all-data')
	// const responseNew = await axios.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-all-projects`)
	// .then((res) => {
	// 	if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
	// 		if ('error' in res.data) {
	// 	throw res.data.error
	// } else {
	// 	throw res.data
	// }
	// 	}
	// 	return res
	// })
	// .catch((err) => {
	// 	if (err) {
	// 		handleError(err.message)
	// 	}
	// 	return err
	// })
	// console.log(responseNew.data)
	return response.data
})

export const getAllProjNames = createAsyncThunk('IMSProjects/getAllProjNames', async () => {
	const currentUser = getUserData()
	const params = {}
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-all-project-names`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return response.data
})

export const getData = createAsyncThunk('IMSProjects/getData', async (params) => {
	const currentUser = getUserData()
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-projects`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(params)
	return {
		params,
		data: response.data.projects || [],
		totalPages: response.data.count || 0
	}
})

export const getAdditionalData = createAsyncThunk('IMSProjects/getAdditionalData', async (params, { dispatch, getState }) => {
	// console.log('🚀 ~ file: index.js:36 ~ getAdditionalData ~ params:', params)
	// const availableData = getState().projects.data
	// const temp = []
	// for (const proj in availableData) {
	// 	console.log('🚀 ~ file: index.js:38 ~ getAdditionalData ~ proj:', proj)
	// 	if (!params.multipleIds.includes(proj.id)) {
	// 		temp.push(proj.id)
	// 	}
	// }
	// console.log(temp)

	// if (temp.length) {
	// 	params.multipleIds = temp.toString()
	// 	const response = await axios.get('${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-projects', { params })
	// .then((res) => {
	// 	if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
	// 		if ('error' in res.data) {
	// 	throw res.data.error
	// } else {
	// 	throw res.data
	// }
	// 	}
	// 	return res
	// })
	// .catch((err) => {
	// 	if (err) {
	// 		handleError(err.message)
	// 	}
	// 	return err
	// })

	// 	return {
	// 		params,
	// 		data: [...getState().projects.data, ...response.data.projects] || [],
	// 		totalPages: response.data.count || 0
	// 	}
	// }
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	if (params.multipleIds) {
		params.multipleIds = params.multipleIds.toString()
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-projects`, { params })
		.then((res) => {
			console.log(res)
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return {
		params,
		data: response.data.projects || [],
		totalPages: response.data.count || 0
	}
})

export const getProjectCounts = createAsyncThunk('IMSProjects/getCounts', async () => {
	// console.log('In get count')
	// const projCountQuery = supabase.from('projects').select(`*`, { count: 'exact', head: true })
	// if (!currentUser.acl.is_super_admin) {
	// 	projCountQuery.in('proj', currentUser.projAccess)
	// }
	// const countRes = await supabase.from('projects').select('*', { count: 'exact', head: true }).then((res) => {
	// 	if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
	// 		if ('error' in res.data) {
	// 	throw res.data.error
	// } else {
	// 	throw res.data
	// }
	// 	}
	// 	return res
	// })
	// .catch((err) => {
	// 	if (err) {
	// 		handleError(err.message)
	// 	}
	// 	return err
	// })
	const currentUser = getUserData()
	const params = {}
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const {
		data: { total, deactivated, active, inactive }
	} = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-projects-count`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return { total, deactivated, active, inactive }
})

export const getProject = createAsyncThunk('IMSProjects/getProject', async (id) => {
	const currentUser = getUserData()
	const params = {}
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	params.id = id
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/get-project-by-id`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	return response.data
})

export const updateProject = createAsyncThunk('IMSProjects/updateProject', async (data, { dispatch, getState }) => {
	// console.log(data)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/update-project`, data)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	await dispatch(getProject(parseInt(data.id)))
	return response.data
})

export const addProject = createAsyncThunk('IMSProjects/addProject', async (project, { dispatch, getState }) => {
	// await axios.post('/apps/users/add-user', user)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/create-project`, project)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})

	await dispatch(getData(getState().projects.params || {}))
	// await dispatch(getAllData())
	return response.data
})

export const deleteProject = createAsyncThunk('IMSProjects/deleteProject', async (data, { dispatch, getState }) => {
	await axios
		.delete(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/projects/delete-project`, { data })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getData(getState().projects.params || {}))
	// await dispatch(getAllData())
	return data
})

export const IMSProjectsSlice = createSlice({
	name: 'IMSProjects',
	initialState: {
		data: [],
		counts: {
			total: 0,
			deactivated: 0,
			active: 0,
			pending: 0
		},
		params: {},
		allProjNames: [],
		allData: [],
		selectedProject: null,
		additionalData: []
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
			})
			.addCase(getAdditionalData.fulfilled, (state, action) => {
				state.additionalData = action.payload.data
			})
			.addCase(getProjectCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.deactivated = action.payload.deactivated
				state.counts.active = action.payload.active
				state.counts.inactive = action.payload.inactive
			})
			.addCase(getProject.fulfilled, (state, action) => {
				state.selectedProject = action.payload
			})
			.addCase(getAllProjNames.fulfilled, (state, action) => {
				state.allProjNames = action.payload
			})
		// .addCase(updateProject.fulfilled, (state, action) => {
		// 	dispatch(getProject(action.payload.id))
		// })
	}
})

export default IMSProjectsSlice.reducer
