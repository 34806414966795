// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import supabase from '../../../../utility/Supabase'
import { getUserData, handleError } from '../../../../utility/Utils'

export const getInventory = createAsyncThunk('IMSInventory/getInventory', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	// console.log(params)
	const { q, sortOrder, sortColumn, status, user, page, perPage, org, proj, manager } = params
	// console.log(['asc', '', undefined].includes(sortOrder))
	const startRange = (page - 1) * perPage
	const endRange = startRange + perPage - 1
	// console.log(startRange)
	// console.log(endRange)

	let sql_query = supabase.from('inventory').select(`*, mat (*, status (status)), org (name), proj (name)`, { count: 'exact' })

	// if (q && q !== '') {
	// 	sql_query = sql_query.or(`name.ilike.%${q}%,l_name.ilike.%${q}%`)
	// 	// sql_query = sql_query.ilike('f_name', `%${q}%`).ilike('l_name', `%${q}%`)
	// }
	// if (status && status !== '') {
	// 	sql_query = sql_query.filter('state', 'eq', parseInt(status))
	// }
	// if (user && user !== '') {
	// 	sql_query = sql_query.filter('user', 'eq', user)
	// }
	if (org && org !== '') {
		sql_query = sql_query.filter('org', 'eq', org)
	}
	if (proj && proj !== '') {
		sql_query = sql_query.filter('proj', 'eq', proj)
	}
	// if (currentUser.projAccess.length > 0) {
	// 	sql_query = sql_query.in('proj', currentUser.projAccess)
	// }
	if (!currentUser.acl.is_super_admin && !currentUser.proj) {
		sql_query.in('proj', currentUser.projAccess)
	} else if (!currentUser.proj) {
		sql_query.eq('org', currentUser.org.id)
	}
	// if (manager && manager !== '') {
	// 	sql_query = sql_query.filter('manager', 'eq', manager)
	// }
	if (sortColumn && sortColumn !== '') {
		sql_query = sql_query.order(sortColumn, { ascending: ['asc', '', undefined].includes(sortOrder) })
	} else {
		sql_query = sql_query.order('id', { ascending: false })
	}
	if (startRange !== undefined && endRange !== undefined) {
		sql_query = sql_query.range(startRange, endRange)
	}

	const { data: inventory, error, count } = await sql_query
	if (error) {
		handleError(error.message)
	}

	return {
		params,
		data: inventory || [],
		totalPages: Math.ceil(count / params.perPage) || 0
	}
})

export const getSingleItem = createAsyncThunk('IMSInventory/getSingleItem', async (params, { dispatch, getState }) => {
	const existingInventory = getState().inventory.data
	const { org, proj, mat } = params
	let sql_query = supabase.from('inventory').select('*').eq('proj', proj).eq('mat', mat)
	if (org) {
		sql_query = sql_query.eq('org', org)
	}
	const { data: fetchedInventory, error } = await sql_query
	// console.log(fetchedInventory)
	if (error) {
		handleError(error.message)
	}

	return {
		data: fetchedInventory,
		mat
	}
})

export const refreshSingleItem = createAsyncThunk('IMSInventory/refreshSingleItem', async (params, { dispatch, getState }) => {
	const inventory = getState().inventory.data
	console.log(params)
	console.log(inventory)
	const newInventory = inventory.map((item) => {
		if (item.id === params.id) {
			const newItem = {
				...item,
				quantity: params.payload.new.quantity
			}
			return newItem
		} else {
			return item
		}
	})

	return {
		data: newInventory || []
	}
})

export const getInventoryCounts = createAsyncThunk('IMSInventory/getCounts', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	const totalQuery = supabase.from('inventory').select('*', { count: 'exact', head: true })
	const availableQuery = supabase.from('inventory').select('*', { count: 'exact', head: true }).gt('quantity', 0)
	const unavailableQuery = supabase.from('inventory').select('*', { count: 'exact', head: true }).eq('quantity', 0)
	if (!currentUser.acl.is_super_admin && !currentUser.proj) {
		totalQuery.in('proj', currentUser.projAccess)
		availableQuery.in('proj', currentUser.projAccess)
		unavailableQuery.in('proj', currentUser.projAccess)
	} else if (!currentUser.proj) {
		totalQuery.eq('org', currentUser.org.id)
		availableQuery.eq('org', currentUser.org.id)
		unavailableQuery.eq('org', currentUser.org.id)
	}
	const { count: total, error: totalError } = await totalQuery
	const { count: available, error: availableError } = await availableQuery
	const { count: unavailable, error: unavailableError } = await unavailableQuery
	// console.log({ totalError, availableError, unavailableError, rejectedError, deliveredError, closedError })
	if (totalError || availableError || unavailableError) {
		;[totalError, availableError, unavailableError].forEach((error) => {
			if (error) {
				handleError(error.message)
			}
		})
	}
	// console.log(total)
	return { total, available, unavailable }
})

export const IMSInventorySlice = createSlice({
	name: 'IMSInventory',
	initialState: {
		data: [],
		counts: {
			total: 0,
			available: 0,
			unavailable: 0
		},
		params: {},
		// allInventory: [],
		materials: {}
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			// .addCase(getAllInventory.fulfilled, (state, action) => {
			// 	state.allInventory = action.payload
			// })
			.addCase(getInventory.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.totalPages = action.payload.totalPages
			})
			.addCase(refreshSingleItem.fulfilled, (state, action) => {
				state.data = action.payload.data
			})
			.addCase(getSingleItem.fulfilled, (state, action) => {
				// state.data = [...state.data, ...action.payload.data]
				// state.data = action.payload.data
				let quantity = 0
				if (action.payload.data.length) {
					quantity = action.payload.data[0].quantity
				}
				let notFoundFlag = true
				let newInventory = state.data.map((item) => {
					if (item.mat === action.payload.mat) {
						const newItem = {
							...item,
							quantity
						}
						notFoundFlag = false
						return newItem
					} else {
						return item
					}
				})
				if (notFoundFlag && action.payload.data.length) {
					newInventory = [...state.data, action.payload.data[0]]
				}
				state.data = newInventory
			})
			// .addCase(getTransactionDetails.fulfilled, (state, action) => {
			// 	state.data = action.payload
			// })
			// .addCase(getSingleTransaction.fulfilled, (state, action) => {
			// 	state.data = action.payload
			// })
			.addCase(getInventoryCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.available = action.payload.available
				state.counts.unavailable = action.payload.unavailable
			})
	}
})

export default IMSInventorySlice.reducer
