// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserData, handleError, handleSuccess } from '../../../../utility/Utils'

// ** Axios Imports
import axios from 'axios'
import supabase from '../../../../utility/Supabase'

export const getAllData = createAsyncThunk('IMSOrganizations/getAllData', async () => {
	const response = await axios.get('/api/users/list/all-data')
	// const responseNew = await axios.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/get-all-organizations`)
	// .then((res) => {
	// 	if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
	// 		if ('error' in res.data) {
	// 	throw res.data.error
	// } else {
	// 	throw res.data
	// }
	// 	}
	// 	return res
	// })
	// .catch((err) => {
	// 	if (err) {
	// 		handleError(err.message)
	// 	}
	// 	return err
	// })
	// console.log(responseNew.data)
	return response.data
})

export const getAllOrgNames = createAsyncThunk('IMSOrganizations/getAllOrgNames', async () => {
	const currentUser = getUserData()
	const params = {}
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/get-all-organization-names`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return response.data
})

export const getData = createAsyncThunk('IMSOrganizations/getData', async (params) => {
	const currentUser = getUserData()
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/get-organizations`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return {
		params,
		data: response.data.organizations || [],
		totalPages: response.data.count || 0
	}
})

export const getOrganizationCounts = createAsyncThunk('IMSOrganizations/getCounts', async (data, { dispatch, getState }) => {
	const currentUser = getUserData()
	const params = {}
	if (!currentUser.acl.is_super_admin) {
		params.org = currentUser.org.id
	}
	const {
		data: { total, paid, active, inactive }
	} = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/get-organizations-count`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return { total, paid, active, inactive }
})

export const getOrganization = createAsyncThunk('IMSOrganizations/getOrganization', async (id) => {
	// console.log('called')
	// console.log(id)
	// const sql_query = supabase.from('organizations').select(`*, status (*)`).eq('id', id)
	// if (query.org) {
	// 	sql_query.eq('id', query.org)
	// }
	// const { data: orgs, error } = await supabase.from('organizations').select(`*, status (*)`).eq('id', id)
	// console.log(error)
	// console.log(orgs)
	// if (error) {
	// 	handleError(err.message)
	// }

	// return orgs

	const currentUser = getUserData()
	if (!currentUser.acl.is_super_admin) {
		id = currentUser.org.id
	}
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/get-organization-by-id`, { params: { id } })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	return response.data
})

export const updateOrganization = createAsyncThunk('IMSOrganizations/updateOrganization', async (data, { dispatch, getState }) => {
	// console.log(data)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/update-organization`, data)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	await dispatch(getOrganization(parseInt(data.id)))
	return response.data
})

export const addOrganization = createAsyncThunk('IMSOrganizations/addOrganization', async (organization, { dispatch, getState }) => {
	// await axios.post('/apps/users/add-user', user)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/create-organization`, organization)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})

	await dispatch(getData(getState().organizations.params || {}))
	// await dispatch(getAllData())
	return response.data
})

export const deleteOrganization = createAsyncThunk('IMSOrganizations/deleteOrganization', async (data, { dispatch, getState }) => {
	await axios
		.delete(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/delete-organization`, { data })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getData(getState().organizations.params || {}))
	// await dispatch(getAllData())
	return data
})

export const IMSOrganizationsSlice = createSlice({
	name: 'IMSOrganizations',
	initialState: {
		data: [],
		counts: {
			total: 0,
			paid: 0,
			active: 0,
			pending: 0
		},
		params: {},
		allData: [],
		allOrgNames: [],
		selectedOrganization: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
			})
			.addCase(getOrganizationCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.paid = action.payload.paid
				state.counts.active = action.payload.active
				state.counts.inactive = action.payload.inactive
			})
			.addCase(getOrganization.fulfilled, (state, action) => {
				state.selectedOrganization = action.payload
			})
			.addCase(getAllOrgNames.fulfilled, (state, action) => {
				state.allOrgNames = action.payload
			})
		// .addCase(updateOrganization.fulfilled, (state, action) => {
		// 	dispatch(getOrganization(action.payload.id))
		// })
	}
})

export default IMSOrganizationsSlice.reducer
