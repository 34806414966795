// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import supabase from '../../../../../utility/Supabase'
import { getUserData, handleError, handleSuccess } from '../../../../../utility/Utils'

export const getAllMaterialTransfers = createAsyncThunk('IMSMaterialTransfers/getAllMaterialTransfers', async (params) => {
	const { data, error } = await supabase.from('material_transfer').select(`*, org (name), from (name), to (name), state (id, state), user (*, role (role), status (status))`, { count: 'exact' })
	if (error) {
		handleError(error.message)
	}
	return data
})

export const setMaterialDetails = createAsyncThunk('IMSMaterialTransfers/getMaterialDetails', async (params, { dispatch, getState }) => {
	// const response = await supabase.from('materials').select(`*, status (status)`).filter('id', 'eq', params.id)
	// console.log(response.data[0])
	// return response.data[0]
	return params
})

// export const getTransactionDetails = createAsyncThunk('IMSMaterialTransfers/getTransactionDetails', async (transactions, { dispatch, getState }) => {
// 	const newTransactions = transactions.map((txn) => {
// 		const newDetails = txn.details.map((param, index) => {
// 			const materialDetails = getState().materialTransfer.materials[param.id]
// 			console.log(materialDetails)
// 			if (materialDetails) {
// 				const newMaterialDetails = {
// 					...materialDetails,
// 					...param
// 				}
// 				return newMaterialDetails
// 			} else {
// 				dispatch(getMaterialDetails(param))
// 			}
// 		})
// 		return {
// 			...txn,
// 			details: newDetails
// 		}
// 	})
// 	console.log(newTransactions)
// 	return newTransactions
// })

export const getMaterialTransfers = createAsyncThunk('IMSMaterialTransfers/getMaterialTransfers', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()

	// console.log(params)
	const { q, sortOrder, sortColumn, status, user, page, perPage, org, from, to, manager } = params
	// console.log(['asc', '', undefined].includes(sortOrder))
	const startRange = (page - 1) * perPage
	const endRange = startRange + perPage - 1
	// console.log(startRange)
	// console.log(endRange)

	let sql_query = supabase.from('material_transfer').select(`*, org (name), from (name), to (name), state (id, state), user (*, role (role), status (status))`, { count: 'exact' })

	// if (q && q !== '') {
	// 	sql_query = sql_query.or(`f_name.ilike.%${q}%,l_name.ilike.%${q}%`)
	// 	// sql_query = sql_query.ilike('f_name', `%${q}%`).ilike('l_name', `%${q}%`)
	// }
	if (status && status !== '') {
		sql_query = sql_query.filter('state', 'eq', parseInt(status))
	}
	if (user && user !== '') {
		sql_query = sql_query.filter('user', 'eq', user)
	}
	if (org && org !== '') {
		sql_query = sql_query.filter('org', 'eq', org)
	}
	// if (proj && proj !== '') {
	// 	sql_query = sql_query.filter('proj', 'eq', parseInt(proj))
	// }
	if (!currentUser.acl.is_super_admin && !currentUser.proj) {
		sql_query.in('from', currentUser.projAccess)
	} else if (!currentUser.proj) {
		sql_query.eq('org', currentUser.org.id)
	}
	if (from && from !== '') {
		sql_query = sql_query.filter('from', 'eq', from)
	}
	if (to && to !== '') {
		sql_query = sql_query.filter('to', 'eq', to)
	}
	// if (manager && manager !== '') {
	// 	sql_query = sql_query.filter('manager', 'eq', manager)
	// }
	if (sortColumn && sortColumn !== '') {
		sql_query = sql_query.order(sortColumn, { ascending: ['asc', '', undefined].includes(sortOrder) })
	} else {
		sql_query = sql_query.order('id', { ascending: false })
	}
	if (startRange !== undefined && endRange !== undefined) {
		sql_query = sql_query.range(startRange, endRange)
	}

	// sql_query = sql_query
	// console.log(sql_query)
	let dispatchCount = 0
	let memoCount = 0
	const memo = {}
	const { data: transactions, error, count } = await sql_query
	if (error) {
		handleError(error.message)
	}
	const newTransactions = []
	for (const txn of transactions) {
		const newDetails = []
		for (const detail of txn.details) {
			// console.log(memo[detail.id], getState().materialTransfer.materials[detail.id])
			const materialDetails = memo[detail.id] || getState().materialTransfer.materials[detail.id] || getState().transactions.materials[detail.id]
			// console.log(materialDetails)
			if (materialDetails) {
				const newMaterialDetails = {
					...materialDetails,
					...detail
				}
				newDetails.push(newMaterialDetails)
				// console.log(newDetails)
				memoCount += 1
				// console.log(`Memo used: ${memoCount}`)
			} else {
				const response = await supabase.from('materials').select(`*, status (status)`).filter('id', 'eq', detail.id)
				// console.log(response)
				memo[detail.id] = response.data[0]
				dispatch(setMaterialDetails(response.data[0]))
				const newMaterialDetails = {
					...response.data[0],
					...detail
				}
				newDetails.push(newMaterialDetails)
				dispatchCount += 1
				// console.log(`Dispatch used: ${dispatchCount}`)
			}
			// console.log(detail)
			// console.log(newDetails)
		}
		newTransactions.push({
			...txn,
			details: newDetails
		})
		// console.log(newTransactions)
	}

	return {
		params,
		data: newTransactions || [],
		totalPages: Math.ceil(count / params.perPage) || 0
	}
})

export const getSingleMaterialTransfer = createAsyncThunk('IMSMaterialTransfers/getSingleMaterialTransfer', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	const existingData = getState().materialTransfer.data
	existingData.forEach(async (txn) => {
		if (txn.id === params.id) {
			console.log('Update found')
			// dispatch(getSingleTransaction({ id: payload.old.id }))
			const txnQuery = supabase.from('material_transfer').select(`*, org (name), from (name), to (name), state (id, state), user (*, role (role), status (status))`).filter('id', 'eq', params.id)
			if (!currentUser.acl.is_super_admin && !currentUser.proj) {
				// txnQuery.in('from', currentUser.projAccess)
				txnQuery.or(`from.in.(${currentUser.projAccess}), to.in.(${currentUser.projAccess})`)
			} else if (!currentUser.proj) {
				txnQuery.eq('org', currentUser.org.id)
			}
			const response = await txnQuery
			const newTransactions = getState().materialTransfer.data.map((txn) => {
				if (txn.id === params.id) {
					return response.data[0]
				} else {
					return txn
				}
			})
			return newTransactions
		}
	})
	return existingData
})

export const getMaterialTransferCounts = createAsyncThunk('IMSMaterialTransfers/getMaterialTransferCounts', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	const totalQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true })
	const awaitingApprovalQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 1)
	const approvedQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 2)
	const rejectedQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 3)
	const inTransitQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 9)
	const deliveredQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 4)
	const confirmedQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 5)
	const closedQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 6)
	// const deactivatedQuery = supabase.from('transactions').select('*', { count: 'exact', head: true }).eq('state', 7)
	const deletedQuery = supabase.from('material_transfer').select('*', { count: 'exact', head: true }).eq('state', 8)

	if (!currentUser.acl.is_super_admin && !currentUser.proj) {
		console.log('Not super admin')
		totalQuery.in('from', currentUser.projAccess)
		awaitingApprovalQuery.in('from', currentUser.projAccess)
		approvedQuery.in('from', currentUser.projAccess)
		rejectedQuery.in('from', currentUser.projAccess)
		inTransitQuery.in('from', currentUser.projAccess)
		deliveredQuery.in('from', currentUser.projAccess)
		confirmedQuery.in('from', currentUser.projAccess)
		closedQuery.in('from', currentUser.projAccess)
		deletedQuery.in('from', currentUser.projAccess)
	} else if (!currentUser.proj) {
		totalQuery.eq('org', currentUser.org.id)
		awaitingApprovalQuery.eq('org', currentUser.org.id)
		approvedQuery.eq('org', currentUser.org.id)
		rejectedQuery.eq('org', currentUser.org.id)
		inTransitQuery.eq('org', currentUser.org.id)
		deliveredQuery.eq('org', currentUser.org.id)
		confirmedQuery.eq('org', currentUser.org.id)
		closedQuery.eq('org', currentUser.org.id)
		deletedQuery.eq('org', currentUser.org.id)

	}
	const { count: total, error: totalError } = await totalQuery
	const { count: awaitingApproval, error: awaitingApprovalError } = await awaitingApprovalQuery
	const { count: approved, error: approvedError } = await approvedQuery
	const { count: rejected, error: rejectedError } = await rejectedQuery
	const { count: inTransit, error: inTransitError } = await inTransitQuery
	const { count: delivered, error: deliveredError } = await deliveredQuery
	const { count: confirmed, error: confirmedError } = confirmedQuery
	const { count: closed, error: closedError } = await closedQuery
	const { count: deleted, error: deletedError } = await deletedQuery
	// console.log({ totalError, awaitingApprovalError, approvedError, rejectedError, deliveredError, closedError })
	if (totalError || awaitingApprovalError || approvedError || rejectedError || inTransitError || deliveredError || confirmedError || closedError || deletedError) {
		// prettier-ignore
		[totalError, awaitingApprovalError, approvedError, rejectedError, inTransitError, deliveredError, confirmedError, closedError, deletedError].forEach((error) => {
			if (error) {
				handleError(error.message)
			}
		})
	}
	console.log(total)

	return { total, awaitingApproval, approved, rejected, inTransit, delivered, closed, confirmed, deleted }
})

export const updateMaterialTransfer = createAsyncThunk('IMSMaterialTransfers/updateMaterialTransfer', async (data, { dispatch, getState }) => {
	// console.log(data)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/transactions/update-material-transfer`, data)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	console.log(response)
	// console.log(response.data)
	await dispatch(getTransactions(data.id))
	return response.data
})

export const addMaterialTransfer = createAsyncThunk('IMSMaterialTransfers/addMaterialTransfer', async (transaction, { dispatch, getState }) => {
	// await axios.post('/apps/transactions/add-transaction', transaction)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/transactions/create-material-transfer`, transaction)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getTransactions(getState().transactions.params))
	// await dispatch(getAllTransaction())
	return response.data
})

export const deleteMaterialTransfer = createAsyncThunk('IMSMaterialTransfers/deleteMaterialTransfer', async (id, { dispatch, getState }) => {
	await axios
		.delete(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/transactions/delete-material-transfer`, { data })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getTransactions(getState().transactions.params))
	return data
})

export const IMSMaterialTransfersSlice = createSlice({
	name: 'IMSMaterialTransfers',
	initialState: {
		data: [],
		counts: {
			total: 0,
			awaitingApproval: 0,
			approved: 0,
			rejected: 0,
			inTransit: 0,
			delivered: 0,
			confirmed: 0,
			closed: 0,
			deleted: 0
		},
		params: {},
		allMaterialTransfers: [],
		materials: {}
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllMaterialTransfers.fulfilled, (state, action) => {
				state.allMaterialTransfers = action.payload
			})
			.addCase(getMaterialTransfers.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.totalPages = action.payload.totalPages
			})
			// .addCase(getTransactionDetails.fulfilled, (state, action) => {
			// 	state.data = action.payload
			// })
			.addCase(getSingleMaterialTransfer.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(getMaterialTransferCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.awaitingApproval = action.payload.awaitingApproval
				state.counts.approved = action.payload.approved
				state.counts.rejected = action.payload.rejected
				state.counts.inTransit = action.payload.inTransit
				state.counts.delivered = action.payload.delivered
				state.counts.confirmed = action.payload.confirmed
				state.counts.closed = action.payload.closed
				state.counts.deleted = action.payload.deleted
			})
		// .addCase(setMaterialTransferDetails.fulfilled, (state, action) => {
		// 	state.materials[action.payload.id] = action.payload
		// })
	}
})

export default IMSMaterialTransfersSlice.reducer
