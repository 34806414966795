import { Coffee, X } from 'react-feather'
import { DefaultRoute, IMSDefaultRoute } from '../router/routes'
import Avatar from '@components/avatar'
import { toast } from 'react-hot-toast'
// import supabase from './Supabase'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
	const today = new Date()
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
	if (!value) return value
	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = { month: 'short', day: 'numeric' }

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' }
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
// export const getProjAccess = async () => {
// 	const temp = []
// 	const user = getUserData()
// 	const proj = user.proj
// 	if (proj) {
// 		temp.push(proj)
// 		Object.keys(user.additional_data.additionalProjectsAccess).forEach((additionalProject) => {
// 			temp.push(parseInt(additionalProject))
// 		})
// 	} else {
// 		const { data: projects, error } = await supabase.from('projects').select('id').eq('org', user.org.id)
// 		projects.forEach((proj) => {
// 			temp.push(parseInt(proj.id))
// 		})
// 	}
// 	return temp
// }

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
	if (userRole === 'admin') return DefaultRoute
	if (userRole === 'authenticated') return IMSDefaultRoute
	if (userRole === 'client') return '/access-control'
	return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#7367f01a', // for option hover bg-color
		primary: '#7367f0', // for selected option bg-color
		neutral10: '#7367f0', // for tags bg-color
		neutral20: '#ededed', // for input border-color
		neutral30: '#ededed' // for input hover border-color
	}
})

export const ToastError = ({ t, message }) => {
	return (
		<div className="d-flex">
			{/* <div className="me-1">
				<Avatar size="sm" color="danger" icon={<X size={12} />} />
			</div> */}
			<div className="d-flex flex-column">
				<div className="d-flex justify-content-between">
					{/* <h6>{name}</h6> */}
					<h6 style={{ color: 'danger' }}>Error!</h6>
					<X size={12} className="cursor-pointer" onClick={() => toast.dismiss(t.id)} />
				</div>
				<span>Something went wrong! Please try again or contact support with message:</span>
				<p
					style={{
						fontFamily: 'monospace',
						color: 'white',
						// background: '#ffeff0',
						background: 'rgb(0,0,0)',
						wordWrap: 'break-word',
						boxDecorationBreak: 'clone',
						padding: '.1rem .3rem .2rem',
						marginTop: '.4rem',
						borderRadius: '.5rem'
					}}
				>
					{message}
				</p>
			</div>
		</div>
	)
}

export const handleError = (error) => {
	toast.error((t) => <ToastError t={t} message={error} />)
	// toast.error(error)
}
export const handleSuccess = (message = '') => {
	if (message) {
		toast.success(message)
	} else {
		toast.success('Success')
	}
	// toast.error(error)
}
