import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/IMS/Users/list'))
const UserView = lazy(() => import('../../views/IMS/Users/view'))
const OrganizationList = lazy(() => import('../../views/IMS/Organizations/list'))
const OrganizationView = lazy(() => import('../../views/IMS/Organizations/view'))
const ProjectList = lazy(() => import('../../views/IMS/Projects/list'))
const ProjectView = lazy(() => import('../../views/IMS/Projects/view'))
const MaterialsList = lazy(() => import('../../views/IMS/Materials/list'))
const MaterialsView = lazy(() => import('../../views/IMS/Materials/view'))
const Info = lazy(() => import('../../views/charts/recharts'))
const Roles = lazy(() => import('../../views/IMS/Users/roles-permissions/roles'))
const Permissions = lazy(() => import('../../views/IMS/Users/roles-permissions/permissions'))
const DashboardIMS = lazy(() => import('../../views/IMS/Dashboard'))
const Faq = lazy(() => import('../../views/pages/faq'))
const AccountSettings = lazy(() => import('../../views/IMS/AccountSettings'))
const Inventory = lazy(() => import('../../views/IMS/Inventory'))
const MaterialIssueRequest = lazy(() => import('../../views/IMS/MaterialIssueRequest'))
const MaterialTransferRequest = lazy(() => import('../../views/IMS/MaterialTransferRequest'))
const CreateGR = lazy(() => import('../../views/IMS/CreateGR'))

const IMSRoutes = [
	{
		path: '/dashboard',
		element: <DashboardIMS />,
		meta: {
			action: 'read',
			resource: 'public'
		}
	},
	{
		path: '/inventory',
		element: <Inventory />,
		meta: {
			action: 'read',
			resource: 'Inventory'
		}
	},
	{
		path: '/material-issue-request',
		element: <MaterialIssueRequest />,
		meta: {
			action: 'read',
			resource: 'Material Issue Request'
		}
	},
	{
		path: '/material-issue-request/:id',
		element: <MaterialIssueRequest />,
		meta: {
			action: 'read',
			resource: 'Material Issue Request'
		}
	},
	{
		path: '/material-transfer-request',
		element: <MaterialTransferRequest />,
		meta: {
			action: 'read',
			resource: 'Material Transfer Request'
		}
	},
	{
		path: '/material-transfer-request/:id',
		element: <MaterialTransferRequest />,
		meta: {
			action: 'read',
			resource: 'Material Transfer Request'
		}
	},
	{
		path: '/create-GR',
		element: <CreateGR />,
		meta: {
			action: 'read',
			resource: 'GR'
		}
	},
	{
		path: '/create-GR/:id',
		element: <CreateGR />,
		meta: {
			action: 'read',
			resource: 'GR'
		}
	},
	// {
	// 	path: '/user/profile/',
	// 	element: <Navigate to={`/user/profile/${user.id}`} />,
	// 	meta: {
	// 		action: 'read',
	// 		resource: 'All'
	// 	}
	// },
	// {
	// 	path: '/user/profile/:id',
	// 	element: <UserView />,
	// 	meta: {
	// 		action: 'read',
	// 		resource: 'All'
	// 	}
	// },
	// {
	// 	path: '/info',
	// 	element: <Info />,
	// 	meta: {
	// 		action: 'read',
	// 		resource: 'public'
	// 	}
	// },
	{
		path: '/user/account-settings',
		element: <AccountSettings />,
		meta: {
			action: 'read',
			resource: 'public'
		}
	},
	{
		path: '/pages/faq',
		element: <Faq />,
		meta: {
			action: 'read',
			resource: 'public'
		}
	},
	{
		element: <Roles />,
		path: '/users/roles',
		meta: {
			action: 'read',
			resource: 'User Management'
		}
	},
	// {
	// 	element: <Permissions />,
	// 	path: '/users/permissions',
	// 	meta: {
	// 		action: 'read',
	// 		resource: 'User Management'
	// 	}
	// },
	{
		element: <UserList />,
		path: '/users/list',
		meta: {
			action: 'read',
			resource: 'User Management'
		}
	},
	{
		path: '/users/view',
		// element: <Navigate to={`/users/view/dcbfe02a-c18c-4d53-be45-20b803f914f9`}/>,
		element: <Navigate to={`/users/view/0`} />,
		meta: {
			action: 'read',
			resource: 'User Management'
		}
	},
	{
		element: <UserView />,
		path: '/users/view/:id',
		meta: {
			action: 'read',
			resource: 'User Management'
		}
	},
	{
		element: <OrganizationList />,
		path: '/organizations/list',
		meta: {
			action: 'read',
			resource: 'Super Admin'
		}
	},
	{
		path: '/organizations/view',
		element: <Navigate to="/organizations/view/1" />,
		meta: {
			action: 'read',
			resource: 'Organization Management'
		}
	},
	{
		element: <OrganizationView />,
		path: '/organizations/view/:id',
		meta: {
			action: 'read',
			resource: 'Organization Management'
		}
	},
	{
		element: <ProjectList />,
		path: '/projects/list',
		meta: {
			action: 'read',
			resource: 'Project Management'
		}
	},
	{
		path: '/projects/view',
		element: <Navigate to="/projects/view/1" />,
		meta: {
			action: 'read',
			resource: 'Project Management'
		}
	},
	{
		element: <ProjectView />,
		path: '/projects/view/:id',
		meta: {
			action: 'read',
			resource: 'Project Management'
		}
	},
	{
		element: <MaterialsList />,
		path: '/materials/list',
		meta: {
			action: 'read',
			resource: 'Materials Management'
		}
	},
	{
		path: '/materials/view',
		element: <Navigate to="/materials/view/1" />
	},
	{
		element: <MaterialsView />,
		path: '/materials/view/:id',
		meta: {
			action: 'read',
			resource: 'Materials Management'
		}
	}
]

export default IMSRoutes
