// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getUserData, handleError, handleSuccess } from '../../../../utility/Utils'

export const getAllData = createAsyncThunk('IMSUsers/getAllData', async () => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/get-all-users`)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return response.data
})

export const getData = createAsyncThunk('IMSUsers/getData', async (params) => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/get-users`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return {
		params,
		data: response.data.users || [],
		totalPages: response.data.total || 0
	}
})

export const getUserNames = createAsyncThunk('IMSUsers/getUserNames', async (params) => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/get-users`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return {
		params,
		data: response.data.users || [],
		totalPages: response.data.total || 0
	}
})

export const getUserCounts = createAsyncThunk('IMSUsers/getCounts', async (params, { dispatch, getState }) => {
	const currentUser = Object.keys(getState().auth.userData).length ? getState().auth.userData : getUserData()
	let parameters = {}
	if (!currentUser.acl.is_super_admin) {
		parameters.org = currentUser.org.id
	}
	const {
		data: { total, active, inactive, deactivated }
	} = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/get-users-count`, { params: parameters })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log({ total, active, inactive, deactivated })
	return { total, active, inactive, deactivated }
})

export const getUser = createAsyncThunk('IMSUsers/getUser', async (id) => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/get-user-by-id`, { params: { id } })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response)
	response.data[0]['fullName'] = `${response.data[0].f_name} ${response.data[0].l_name}`
	return response.data
})

export const updateUser = createAsyncThunk('IMSUsers/updateUser', async (data, { dispatch, getState }) => {
	// console.log(data)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/update-user`, data)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	await dispatch(getUser(data.id))
	return response.data
})

export const addUser = createAsyncThunk('IMSUsers/addUser', async (user, { dispatch, getState }) => {
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/users/create-user`, user)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getData(getState().users.params))
	return response.data
})

export const deleteUser = createAsyncThunk('IMSUsers/deleteUser', async (id, { dispatch, getState }) => {
	await axios
		.delete(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/organizations/delete-user`, { data })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getData(getState().users.params))
	return data
})

export const IMSUsersSlice = createSlice({
	name: 'IMSUsers',
	initialState: {
		data: [],
		counts: {
			total: 0,
			suspended: 0,
			active: 0,
			inactive: 0
		},
		params: {},
		allData: [],
		userNames: [],
		selectedUser: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				action.payload.data.forEach((item) => {
					item.fullName = `${item.f_name} ${item.l_name}`
				})
				// console.log(action.payload.data)
				state.data = action.payload.data
				state.params = action.payload.params
				state.userNames = action.payload.data
			})
			.addCase(getUserNames.fulfilled, (state, action) => {
				action.payload.data.forEach((item) => {
					item.fullName = `${item.f_name} ${item.l_name}`
				})
				// console.log(action.payload.data)
				state.userNames = action.payload.data
				// state.params = action.payload.params
			})
			// total, active, inactive, deactivated
			.addCase(getUserCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.active = action.payload.active
				state.counts.inactive = action.payload.inactive
				state.counts.deactivated = action.payload.deactivated
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.selectedUser = action.payload
			})
	}
})

export default IMSUsersSlice.reducer
