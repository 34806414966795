// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { toast } from 'react-hot-toast'

import IMSRoutes from '../navigation/vertical/ims'
import supabase from '../utility/Supabase'
import { handleError, getUserData, updateUserData } from '../utility/Utils'

export const getBookmarks = createAsyncThunk('navbar/getBookmarks', async () => {
	const currentUser = JSON.parse(localStorage.getItem('userData'))
	const { data: profiles, error } = await supabase.from('profiles').select('*').eq('id', currentUser.id)
	if (error) {
		handleError(error.message)
	}

	let bookmarksTargets = []
	let bookmarks = []
	if ('bookmarks' in profiles[0].additional_data) {
		bookmarksTargets = profiles[0].additional_data.bookmarks
	}
	const suggestions = []
	let index = 1
	IMSRoutes.forEach((route) => {
		if ('children' in route) {
			route.children.forEach((childRoute) => {
				const childTemp = {
					id: index,
					target: childRoute.id,
					isBookmarked: bookmarksTargets.includes(childRoute.id),
					title: childRoute.title,
					icon: route.bookmarkIcon,
					link: childRoute.navLink
				}
				suggestions.push(childTemp)
				if (bookmarksTargets.includes(childRoute.id)) {
					bookmarks.push(childTemp)
				}
				index++
			})
		} else {
			const temp = {
				id: index,
				target: route.id,
				isBookmarked: bookmarksTargets.includes(route.id),
				title: route.title,
				icon: route.bookmarkIcon,
				link: route.navLink
			}
			suggestions.push(temp)
			if (bookmarksTargets.includes(route.id)) {
				bookmarks.push(temp)
			}
			index++
		}
	})
	// const response = await axios.get('/api/bookmarks/data')
	// console.log(response)
	// console.log(suggestions)
	// console.log(bookmarks)
	// return {
	// 	data: response.data.suggestions,
	// 	bookmarks: response.data.bookmarks
	// }
	return {
		data: suggestions,
		bookmarks
	}
})

export const updateBookmarked = createAsyncThunk('navbar/updateBookmarked', async ({ id, target }, { dispatch, getState }) => {
	// await axios.post('/api/bookmarks/update', { id })
	const currentUserID = JSON.parse(localStorage.getItem('userData')).id
	const { data: profiles, error } = await supabase.from('profiles').select('*').eq('id', currentUserID)

	if (error) {
		handleError(error.message)
	}

	const currentUser = profiles[0]

	const bookmarks = []
	getState().navbar.bookmarks.forEach((bookmark) => {
		bookmarks.push(bookmark.target)
	})
	console.log(bookmarks)
	// let bookmarks = []
	// if ('bookmarks' in currentUser.additional_data) {
	// 	bookmarks = currentUser.additional_data.bookmarks
	// }

	// ** Get index to add or remove bookmark from array
	const bookmarkIndex = bookmarks.findIndex((x) => x === target)

	if (bookmarkIndex === -1) {
		bookmarks.push(target)
	} else {
		bookmarks.splice(bookmarkIndex, 1)
	}
	const updatedAdditionalData = { ...currentUser.additional_data, bookmarks }
	const updatedCurrentUser = { ...currentUser, additional_data: updatedAdditionalData }

	const { data, updateError } = await supabase.from('profiles').update({ additional_data: updatedAdditionalData }).eq('id', currentUserID)
	// updateUserData(updatedCurrentUser)

	return id
})

export const navbarSlice = createSlice({
	name: 'navbar',
	initialState: {
		query: '',
		bookmarks: [],
		suggestions: []
	},
	reducers: {
		handleSearchQuery: (state, action) => {
			state.query = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBookmarks.fulfilled, (state, action) => {
				state.suggestions = action.payload.data
				state.bookmarks = action.payload.bookmarks
			})
			.addCase(updateBookmarked.fulfilled, (state, action) => {
				let objectToUpdate

				// ** find & update object
				state.suggestions.find((item) => {
					if (item.id === action.payload) {
						item.isBookmarked = !item.isBookmarked
						objectToUpdate = item
					}
				})

				// ** Get index to add or remove bookmark from array
				const bookmarkIndex = state.bookmarks.findIndex((x) => x.id === action.payload)

				if (bookmarkIndex === -1) {
					state.bookmarks.push(objectToUpdate)
				} else {
					state.bookmarks.splice(bookmarkIndex, 1)
				}
			})
	}
})

export const { handleSearchQuery } = navbarSlice.actions

export default navbarSlice.reducer
