// ** Icons Import
import { Home, Circle, Package, User, Info, UserPlus, Users, Truck, Codesandbox, Codepen, PlusSquare, Box } from 'react-feather'

export default [
	{
		id: 'dashboard',
		title: 'Dashboard',
		icon: <Home size={20} />,
		bookmarkIcon: 'Home',
		action: 'read',
		resource: 'public',
		navLink: '/dashboard'
	},
	{
		id: 'inventory',
		title: 'Inventory',
		icon: <Package size={20} />,
		bookmarkIcon: 'Package',
		action: 'read',
		resource: 'Inventory',
		navLink: '/inventory'
	},
	{
		id: 'material-issue-request',
		title: 'Issue Material',
		icon: <Truck size={20} />,
		bookmarkIcon: 'Truck',
		action: 'read',
		resource: 'Material Issue Request',
		navLink: '/material-issue-request'
	},
	{
		id: 'material-transfer-request',
		title: 'Transfer Material',
		icon: <Truck size={20} />,
		bookmarkIcon: 'Truck',
		action: 'read',
		resource: 'Material Transfer Request',
		navLink: '/material-transfer-request'
	},
	{
		id: 'users',
		title: 'User Management',
		icon: <Users size={20} />,
		bookmarkIcon: 'Users',
		children: [
			{
				id: 'users-list',
				title: 'All Users',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'User Management',
				navLink: '/users/list'
			},
			{
				id: 'users-view',
				title: 'View User',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'User Management',
				navLink: '/users/view'
			},
			{
				id: 'users-roles',
				title: 'Roles',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'User Management',
				navLink: '/users/roles'
			}
			// {
			// 	id: 'permissions',
			// 	title: 'Permissions',
			// 	icon: <Circle size={12} />,
			// 	action: 'read',
			// 	resource: 'User',
			// 	navLink: '/users/permissions'
			// }
		]
	},
	{
		id: 'organizations',
		title: 'Organization Management',
		icon: <Codesandbox size={20} />,
		bookmarkIcon: 'Codesandbox',
		children: [
			{
				id: 'organizations-list',
				title: 'All Organizations',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				// resource: 'Organization Management',
				resource: 'Super Admin',
				navLink: '/organizations/list'
			},
			{
				id: 'organization-view',
				title: 'View Organization',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'Organization Management',
				navLink: '/organizations/view'
			}
		]
	},
	{
		id: 'projects',
		title: 'Project Management',
		icon: <Codepen size={20} />,
		bookmarkIcon: 'Codepen',
		children: [
			{
				id: 'projects-list',
				title: 'All Projects',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'Project Management',
				navLink: '/projects/list'
			},
			{
				id: 'project-view',
				title: 'View Project',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'Project Management',
				navLink: '/projects/view'
			}
		]
	},
	{
		id: 'materials',
		title: 'Material Management',
		icon: <Box size={20} />,
		bookmarkIcon: 'Box',
		children: [
			{
				id: 'materials-list',
				title: 'All Materials',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'Material Management',
				navLink: '/materials/list'
			},
			{
				id: 'materials-view',
				title: 'View Material',
				icon: <Circle size={12} />,
				bookmarkIcon: 'Circle',
				action: 'read',
				resource: 'Material Management',
				navLink: '/materials/view'
			}
		]
	},
	{
		id: 'create-GR',
		title: 'Create GR',
		icon: <PlusSquare size={20} />,
		bookmarkIcon: 'PlusSquare',
		action: 'read',
		resource: 'GR',
		navLink: '/create-GR'
	},
	{
		id: 'account-settings',
		title: 'Account Settings',
		icon: <User size={20} />,
		bookmarkIcon: 'User',
		action: 'read',
		resource: 'public',
		navLink: '/user/account-settings'
	},
	{
		id: 'info',
		title: 'Info',
		icon: <Info size={20} />,
		bookmarkIcon: 'Info',
		action: 'read',
		resource: 'public',
		navLink: '/pages/faq'
	}
]
