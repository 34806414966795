// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { handleError, handleSuccess } from '../../../../utility/Utils'

export const getAllData = createAsyncThunk('IMSMaterials/getAllData', async () => {
	const response = await axios.get('/api/users/list/all-data')
	// const responseNew = await axios.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/get-all-materials`)
	// .then((res) => {
	// 	if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
	// 		if ('error' in res.data) {
	// 	throw res.data.error
	// } else {
	// 	throw res.data
	// }
	// 	}
	// 	return res
	// })
	// .catch((err) => {
	// 	if (err) {
	// 		handleError(err.message)
	// 	}
	// 	return err
	// })
	// console.log(responseNew.data)
	return response.data
})

export const getAllMatNames = createAsyncThunk('IMSMaterials/getAllMatNames', async () => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/get-all-material-names`)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return response.data
})

export const getData = createAsyncThunk('IMSMaterials/getData', async (params) => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/get-materials`, { params })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(params)
	return {
		params,
		data: response.data.materials || [],
		totalPages: response.data.count || 0
	}
})

export const getMaterialCounts = createAsyncThunk('IMSMaterials/getCounts', async () => {
	const {
		data: { total, paid, active, inactive }
	} = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/get-materials-count`)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	return { total, paid, active, inactive }
})

export const getMaterial = createAsyncThunk('IMSMaterials/getMaterial', async (id) => {
	const response = await axios
		.get(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/get-material-by-id`, { params: { id } })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	return response.data
})

export const updateMaterial = createAsyncThunk('IMSMaterials/updateMaterial', async (data, { dispatch, getState }) => {
	// console.log(data)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/update-material`, data)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	// console.log(response.data)
	await dispatch(getMaterial(parseInt(data.id)))
	return response.data
})

export const addMaterial = createAsyncThunk('IMSMaterials/addMaterial', async (material, { dispatch, getState }) => {
	// await axios.post('/apps/users/add-user', user)
	const response = await axios
		.post(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/create-material`, material)
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})

	await dispatch(getData(getState().materials.params || {}))
	// await dispatch(getAllData())
	return response.data
})

export const deleteMaterial = createAsyncThunk('IMSMaterials/deleteMaterial', async (data, { dispatch, getState }) => {
	await axios
		.delete(`${process.env.REACT_APP_SUPABASE_EDGE_URL}/materials/delete-material`, { data })
		.then((res) => {
			if (typeof res.data === 'object' && ('message' in res.data || 'error' in res.data)) {
				if ('error' in res.data) {
					throw res.data.error
				} else {
					throw res.data
				}
			}
			handleSuccess()
			return res
		})
		.catch((err) => {
			if (err) {
				handleError(err.message)
			}
			return err
		})
	await dispatch(getData(getState().materials.params || {}))
	// await dispatch(getAllData())
	return data
})

export const IMSMaterialsSlice = createSlice({
	name: 'IMSMaterials',
	initialState: {
		data: [],
		counts: {
			total: 0,
			paid: 0,
			active: 0,
			pending: 0
		},
		params: {},
		allData: [],
		selectedMaterial: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
			})
			.addCase(getMaterialCounts.fulfilled, (state, action) => {
				state.counts.total = action.payload.total
				state.counts.paid = action.payload.paid
				state.counts.active = action.payload.active
				state.counts.inactive = action.payload.inactive
			})
			.addCase(getMaterial.fulfilled, (state, action) => {
				state.selectedMaterial = action.payload
			})
			.addCase(getAllMatNames.fulfilled, (state, action) => {
				state.allMatNames = action.payload
			})
		// .addCase(updateMaterial.fulfilled, (state, action) => {
		// 	dispatch(getMaterial(action.payload.id))
		// })
	}
})

export default IMSMaterialsSlice.reducer
