import { createClient } from '@supabase/supabase-js'
// import * as dotenv from 'dotenv'
// dotenv.config()

// const supabaseUrl = 'https://supabase-api.ravimakes.com'
// const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgInJvbGUiOiAiYW5vbiIsCiAgICAiaXNzIjogInN1cGFiYXNlIiwKICAgICJpYXQiOiAxNjY5NDg3NDAwLAogICAgImV4cCI6IDE4MjcyNTM4MDAKfQ.ay4sbMkHnEOCMrEourOaf6ZiPVSmrOY42DH5ORexatg'

// const supabaseUrl = 'http://192.168.1.100:54321'
// const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY

const options = {
	schema: 'public',
	autoRefreshToken: true,
	persistSession: true,
	detectSessionInUrl: true
}

const supabase = createClient(supabaseUrl, supabaseKey, options)

export default supabase
