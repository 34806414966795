// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/IMS/Users/store'
import materials from '@src/views/IMS/Materials/store'
import organizations from '@src/views/IMS/Organizations/store'
import projects from '@src/views/IMS/Projects/store'
import transactions from '@src/views/IMS/Dashboard/Transactions/store'
import materialTransfer from '@src/views/IMS/Dashboard/MaterialTransfer/store'
import inventory from '@src/views/IMS/Inventory/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
	auth,
	todo,
	chat,
	email,
	users,
	materials,
	organizations,
	projects,
	transactions,
	materialTransfer,
	inventory,
	kanban,
	navbar,
	layout,
	invoice,
	calendar,
	ecommerce,
	dataTables,
	permissions
}

export default rootReducer
